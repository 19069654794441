import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { City, Street } from '../services/suggestionApi';
import { SearchAddressResponse, searchAddress } from '../services/searchApi';

export const emptyCity: City = {
  id: 0,
  city: '',
  commune: '',
  county: '',
  province: '',
};

export const emptyStreet: Street = {
  id: 0,
  type: '',
  street: '',
  city: '',
};

const emptyAddressSearch = {
  city: emptyCity,
  street: emptyStreet,
  streetNumber: '',
  flatNumber: '',
  page: 1,
};

export interface AddressSearchState {
  currentAddressSearch: {
    city: City;
    street?: Street;
    streetNumber: string;
    flatNumber: string;
    page: number;
  };
  searchAddressResults: SearchAddressResponse | null;
  isLoading: boolean;
  error: string | null;
  searchAddress: (params: {
    city: City;
    street?: Street;
    streetNumber: string;
    flatNumber: string;
    page: number;
  }) => Promise<void>;
  scrollToResults: boolean;
  setScrollToResults: (value: boolean) => void;
  streetInputValue: string;
  setStreetInputValue: (value: string) => void;
  setCurrentAddressSearchCity: (city: City) => void;
  setCurrentAddressSearchStreet: (street?: Street) => void;
  setCurrentAddressSearchStreetNumber: (streetNumber: string) => void;
  setCurrentAddressSearchFlatNumber: (flatNumber: string) => void;
  clearSearch: () => void;
  setClearSuggestionInput: (value: boolean) => void;
  clearSuggestionInput: boolean;
  clearAddressSearchResults: () => void;
}

export const useAddressSearchStore = create<AddressSearchState>()(
  persist(
    (set, get, api) => ({
      searchAddressResults: null,
      isLoading: false,
      error: null,
      currentAddressSearch: emptyAddressSearch,
      streetInputValue: '',
      setStreetInputValue: (value: string) => {
        console.log('Setting street value:', value);
        set({ streetInputValue: value });
      },
      searchAddress: async (params: {
        city: City;
        street?: Street;
        streetNumber: string;
        flatNumber: string;
        page: number;
      }) => {
        console.log('Search called with params:', params);
        const requestParams = {
          cityId: params.city.id,
          streetId: params.street?.id,
          streetNumber: params.streetNumber,
          flatNumber: params.flatNumber,
          page: params.page,
        };

        console.log('Request params:', requestParams);
        set({
          currentAddressSearch: params,
          searchAddressResults: null,
          isLoading: true,
          error: null,
        });

        try {
          const response = await searchAddress(requestParams);
          console.log('Search response:', response);
          set({ searchAddressResults: response, isLoading: false });
        } catch (error) {
          console.error('Search error:', error);
          set({ error: 'Failed to search address', isLoading: false });
        }
      },
      scrollToResults: false,
      setScrollToResults: (value: boolean) => set({ scrollToResults: value }),
      clearSearch: () => {
        set({
          currentAddressSearch: emptyAddressSearch,
        });
      },
      setCurrentAddressSearchCity: (city: City) =>
        set((state) => ({
          currentAddressSearch: { ...state.currentAddressSearch, city },
        })),
      setCurrentAddressSearchStreet: (street?: Street) =>
        set((state) => ({
          currentAddressSearch: { ...state.currentAddressSearch, street: street || undefined },
        })),
      setCurrentAddressSearchStreetNumber: (streetNumber: string) =>
        set((state) => ({
          currentAddressSearch: { ...state.currentAddressSearch, streetNumber },
        })),
      setCurrentAddressSearchFlatNumber: (flatNumber: string) =>
        set((state) => ({
          currentAddressSearch: { ...state.currentAddressSearch, flatNumber },
        })),
      clearSuggestionInput: false,
      setClearSuggestionInput: (value: boolean) => set({ clearSuggestionInput: value }),
      clearAddressSearchResults: () => {
        set({ searchAddressResults: null });
      },
    }),
    {
      name: 'address-search-storage',
      partialize: (state) => ({
        currentAddressSearch: state.currentAddressSearch,
        searchAddressResults: state.searchAddressResults,
      }),
      version: 1,
      migrate: (persistedState: any, version: number) => {
        return persistedState;
      },
    },
  ),
);
