import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './DetailsModal.scss';

import { BookPreviewData } from '../../services/previewApi';
import useAuthStore from '../../store/authStore';
import useOrderStore from '../../store/orderStore';
import { Link, useNavigate } from 'react-router-dom';
import BookTable from '../BookTable';
import { navigateToEKW } from '../../helpers/helpers';

interface DetailsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  previewData: BookPreviewData;
}

const concatBookIdentifier = (previewData: BookPreviewData) => {
  return `${previewData.department}/${previewData.register}/${previewData.control_number}`;
};

const DetailsModal: React.FC<DetailsModalProps> = ({ isOpen, closeModal, previewData }) => {
  const [bookIdentifier, setBookIdentifier] = useState<string | null>(null);

  useEffect(() => {
    if (previewData) {
      setBookIdentifier(concatBookIdentifier(previewData));
    }
  }, [previewData]);

  const { setCurrentOrder, isLoading } = useOrderStore();
  const { isLoggedIn } = useAuthStore();
  const isBookPurchased = previewData.purchased;
  const navigate = useNavigate();

  const orderBook = () => {
    setCurrentOrder(previewData.id, previewData);
    navigate('/zamowienie');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '80vh',
          width: '90vw',
          maxWidth: '500px',
          backgroundColor: 'white',
          zIndex: 10000,
          padding: '0',
        },
      }}
    >
      <div className="col-12 g0">
        <div className="modal-header row px-0 py-3 g-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            <button
              className="modal-close-btn"
              onClick={closeModal}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer',
                padding: '5px 10px',
                visibility: 'hidden',
              }}
            >
              ✕
            </button>
            <h3 className="text-center mb-0">{bookIdentifier}</h3>
            <button
              className="modal-close-btn"
              onClick={closeModal}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer',
                padding: '5px 10px',
              }}
            >
              ✕
            </button>
          </div>
          {/* {!isLoggedIn && (
            <div className="col-12 flex justify-content-center">
              <p className="text-center pt-10">
                <Link to="/logowanie">Zaloguj się</Link> aby kupić
              </p>
            </div>
          )}
          {isLoggedIn && !isLoading && !isBookPurchased && (
            <button className="col-4 flex modal-close" onClick={orderBook}>
              Zakup
            </button>
          )} */}
          {isBookPurchased && (
            <button className="col-8 mt-10 flex modal-close" onClick={navigateToEKW}>
              Zobacz pełną treść księgi
            </button>
          )}
        </div>
        <div className="modal-body">
          <BookTable previewData={previewData} />
        </div>
      </div>
    </Modal>
  );
};

export default DetailsModal;
