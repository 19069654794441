import axios from './axios';

export interface PaymentStats {
  today: DailyStats;
  yesterday: DailyStats;
  totalUsers: number;
}

interface DailyStats {
  total: number;
  regularAmount: number;
  guestAmount: number;
  regularTransactions: number;
  guestTransactions: number;
  totalTransactions: number;
  newUsers: number;
}

export const fetchPaymentStats = async (): Promise<PaymentStats> => {
  try {
    const response = await axios.get('/admin/payment-stats');
    return response.data;
  } catch (error) {
    console.error('Error fetching payment stats:', error);
    throw error;
  }
};
