import React from 'react';
import Tippy from '@tippyjs/react';
import styles from '../SearchForm.module.scss';

interface SearchInputProps {
  placeholder: string;
  icon?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, icon, disabled = false, value, onChange, error }) => {
  return (
    <Tippy content={'Wybierz miejscowość'} disabled={!disabled}>
      <div className={`search-input ${error ? styles.error : ''}`}>
        <label htmlFor="keyword">{icon && <i className={`${icon} theme-color`}></i>}</label>
        <input
          type="text"
          className="text-black"
          name="keyword"
          id="keyword"
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
        />
        {error && <div className={styles.errorText}>{error}</div>}
      </div>
    </Tippy>
  );
};

export default SearchInput;
