import React, { useState } from 'react';
import { LotResult } from '../../services/searchApi';
import { BookPreviewData, fetchPreview } from '../../services/previewApi';
import DetailsModal from '../details-modal/DetailsModal';
import { useNavigate } from 'react-router-dom';
import useOrderStore from '../../store/orderStore';
import { formatArea, isBook } from './helpers';

interface AddressBookItemProps {
  result: LotResult;
}

const LotBookItem: React.FC<AddressBookItemProps> = ({ result }) => {
  const book = result.book;
  const bookIdentifier = book ? `${book.department}/${book.register}/${book.control_number}` : null;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewData, setBookPreviewData] = useState<BookPreviewData | null>(null);
  const navigate = useNavigate();
  const { setCurrentOrder } = useOrderStore();

  const isBookDiscovered = !book?.register.includes('X');

  const handleDetailsClick = async () => {
    if (book) {
      const data = await fetchPreview(book.id);
      setBookPreviewData(data);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onBuyBookClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (isBookDiscovered) {
      return null;
    }

    if (isBook(book)) {
      setCurrentOrder(book.id);

      return navigate('/zamowienie');
    }
  };

  const formattedArea = formatArea(result.area);

  return (
    <div className="single-item-grid">
      <div className="row align-items-center g-0">
        <div className="col-lg-7 col-md-6 col-12">
          <div className="row m-0">
            <div className="content col-12">
              <div className="row m-0">
                <div className="col-12">
                  <p className="text-black">
                    Identyfikator: <b>{result.identifier ? result.identifier : '---'}</b>
                  </p>
                </div>
                <div className="col-12">
                  <p className="location text-black">Numer działki: {result.number}</p>
                </div>
                {formattedArea !== '0.00' && (
                  <div className="col-12">
                    <p className="location text-black">
                      Powierzchnia: {result.area ? formatArea(result.area) : '---'}&nbsp;m<sup>2</sup>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-12">
          {bookIdentifier ? (
            <div className="row mx-10 buttons">
              <div className="col-lg-6 col-md-6 col-6 mb-2 mb-md-0 d-flex justify-content-md-end justify-content-center">
                <button className="btn btn-secondary w-100 w-md-auto" onClick={handleDetailsClick}>
                  Szczegóły
                </button>
              </div>
              <div className="col-lg-6 col-md-6 col-6">
                <button
                  className="btn btn-primary w-100 text-center justify-content-md-start justify-content-center"
                  onClick={onBuyBookClick}
                >
                  {isBookDiscovered ? bookIdentifier : `Odkryj numer ${bookIdentifier}`}
                </button>
              </div>
            </div>
          ) : (
            <div className="buttons d-flex justify-content-center">
              <p>Brak księgi</p>
            </div>
          )}
        </div>
      </div>

      {previewData && <DetailsModal isOpen={isModalOpen} closeModal={closeModal} previewData={previewData} />}
    </div>
  );
};

export default LotBookItem;
