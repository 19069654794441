import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useStore from '../../store/store';
import useAuthStore from '../../store/authStore';
import NavigationItem from './NavigationItem';
import './navigation.css';

const Navigation: React.FC = () => {
  const { isMobileMenuOpen, toggleMobileMenu, closeMobileMenu } = useStore();
  const location = useLocation();
  const { isLoggedIn, logout, isAdmin } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      closeMobileMenu();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [closeMobileMenu]);

  useEffect(() => {
    closeMobileMenu();
  }, [location, closeMobileMenu]);

  const onLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
      <button
        className="lg:hidden p-2 focus:outline-none"
        type="button"
        onClick={toggleMobileMenu}
        aria-label="Toggle navigation"
      >
        <div className="space-y-2">
          <span className="block w-8 h-0.5 bg-gray-600"></span>
          <span className="block w-8 h-0.5 bg-gray-600"></span>
          <span className="block w-8 h-0.5 bg-gray-600"></span>
        </div>
      </button>

      <div
        className={`mobile-menu-enter ${isMobileMenuOpen ? 'active' : ''} lg:block absolute lg:relative left-0 top-[100%] bg-white w-full lg:w-auto lg:bg-transparent z-50`}
      >
        <ul className="flex flex-col lg:flex-row lg:items-center divide-y lg:divide-y-0 divide-gray-100">
          <li className="px-4 lg:px-0">
            <NavigationItem to="/ksiegi-wieczyste-po-adresie">Wyszukaj po adresie</NavigationItem>
          </li>
          <li className="px-4 lg:px-0">
            <NavigationItem to="/ksiegi-wieczyste-po-nr-dzialki">Wyszukaj po numerze działki</NavigationItem>
          </li>
          <li className="px-4 lg:px-0">
            <NavigationItem to="/#pakiety">Pakiety</NavigationItem>
          </li>
          <li className="px-4 lg:px-0">
            <NavigationItem to="/kontakt">Kontakt</NavigationItem>
          </li>
          {isAdmin && (
            <li className="px-4 lg:px-0">
              <NavigationItem to="/admin/stats">Statystyki</NavigationItem>
            </li>
          )}
          <div className="lg:hidden">
            {isLoggedIn ? (
              <>
                <li className="px-4">
                  <NavigationItem to="/moje-konto/ksiegi" icon="lni-user" isNavLink>
                    Moje konto
                  </NavigationItem>
                </li>
                <li className="px-4">
                  <NavigationItem to="/" icon="lni-exit" isNavLink onClick={onLogout}>
                    Wyloguj
                  </NavigationItem>
                </li>
              </>
            ) : (
              <>
                <li className="px-4">
                  <NavigationItem to="/logowanie" icon="lni-enter" isNavLink>
                    Logowanie
                  </NavigationItem>
                </li>
                <li className="px-4">
                  <NavigationItem to="/rejestracja" icon="lni-user" isNavLink>
                    Rejestracja
                  </NavigationItem>
                </li>
              </>
            )}
          </div>
        </ul>
      </div>
    </>
  );
};

export default Navigation;
