import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import { PaymentStats, fetchPaymentStats } from '../services/adminApi';
import useDocumentTitle from '../hooks/use-document-title';

const AdminStats: React.FC = () => {
  const [stats, setStats] = useState<PaymentStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { isLoggedIn, isAdmin } = useAuthStore();

  useDocumentTitle('Statystyki płatności');

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/logowanie');
      return;
    }

    if (!isAdmin) {
      navigate('/');
      return;
    }

    const loadStats = async () => {
      try {
        setLoading(true);
        const data = await fetchPaymentStats();
        setStats(data);
      } catch (err) {
        setError('Wystąpił błąd podczas ładowania statystyk.');
      } finally {
        setLoading(false);
      }
    };

    loadStats();
  }, [isLoggedIn, isAdmin, navigate]);

  if (loading) {
    return (
      <section className="section flex flex-grow-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Ładowanie...</h2>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="section flex flex-grow-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="alert alert-danger">{error}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="section flex flex-grow-1">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Statystyki płatności</h2>
            {stats && (
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Dzisiaj</h5>
                      <ul className="list-unstyled">
                        <li>Suma: {stats.today.total} PLN</li>
                        <li>Użytkownicy zarejestrowani: {stats.today.regularAmount} PLN</li>
                        <li>Użytkownicy niezarejestrowani: {stats.today.guestAmount} PLN</li>
                        <li>Liczba transakcji (zarejestrowani): {stats.today.regularTransactions}</li>
                        <li>Liczba transakcji (niezarejestrowani): {stats.today.guestTransactions}</li>
                        <li>Suma transakcji: {stats.today.totalTransactions}</li>
                        <li>Nowi użytkownicy: {stats.today.newUsers}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Wczoraj</h5>
                      <ul className="list-unstyled">
                        <li>Suma: {stats.yesterday.total} PLN</li>
                        <li>Użytkownicy zarejestrowani: {stats.yesterday.regularAmount} PLN</li>
                        <li>Użytkownicy niezarejestrowani: {stats.yesterday.guestAmount} PLN</li>
                        <li>Liczba transakcji (zarejestrowani): {stats.yesterday.regularTransactions}</li>
                        <li>Liczba transakcji (niezarejestrowani): {stats.yesterday.guestTransactions}</li>
                        <li>Suma transakcji: {stats.yesterday.totalTransactions}</li>
                        <li>Nowi użytkownicy: {stats.yesterday.newUsers}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Ogólne</h5>
                      <p>Liczba wszystkich użytkowników: {stats.totalUsers}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminStats;
