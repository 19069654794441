import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { City } from '../services/suggestionApi';
import { SearchLotResponse, searchLot } from '../services/searchApi';

export const emptyCity: City = {
  id: 0,
  city: '',
  commune: '',
  county: '',
  province: '',
};

const emptyLotSearch = {
  identifier: '',
  city: emptyCity,
  street: '',
  number: '',
  page: 0,
};

interface LotSearchState {
  setScrollToResults: any;
  currentLotSearch: {
    identifier: string;
    city: City | null;
    street: string;
    number: string;
    page: number;
  };
  searchLotResults: SearchLotResponse | null;
  isLoading: boolean;
  error: string | null;
  searchLot: (params: {
    identifier: string;
    city: City | null;
    street: string;
    number: string;
    page: number;
  }) => Promise<void>;
  scrollToResults: boolean;
  setCurrentLotSearchIdentifier: (identifier: string) => void;
  setCurrentLotSearchCity: (city: City | null) => void;
  setCurrentLotSearchStreet: (street: string) => void;
  setCurrentLotSearchLotNumber: (lotNumber: string) => void;
  clearSearch: () => void;
  setClearSuggestionInput: (value: boolean) => void;
  clearSuggestionInput: boolean;
  clearLotSearchResults: () => void;
}

export const useLotSearchStore = create<LotSearchState>()(
  persist(
    (set) => ({
      searchLotResults: null,
      isLoading: false,
      error: null,
      currentLotSearch: emptyLotSearch,
      searchLot: async (params: {
        identifier: string;
        city: City | null;
        street: string;
        number: string;
        page: number;
      }) => {
        set({ searchLotResults: null });
        set({ currentLotSearch: params });
        set({ isLoading: true, error: null });
        const requestParams = {
          ...params,
          cityId: params.city?.id,
        };

        try {
          const response = await searchLot(requestParams);
          set({ searchLotResults: response, isLoading: false });
        } catch (error) {
          set({ error: 'Failed to search lot', isLoading: false });
        }
      },
      scrollToResults: false,
      setScrollToResults: (value: boolean) => set({ scrollToResults: value }),
      clearSearch: () => {
        set({
          currentLotSearch: emptyLotSearch,
        });
      },
      setCurrentLotSearchIdentifier: (identifier: string) =>
        set((state) => ({
          currentLotSearch: { ...state.currentLotSearch, identifier },
        })),
      setCurrentLotSearchCity: (city: City | null) =>
        set((state) => ({
          currentLotSearch: { ...state.currentLotSearch, city },
        })),
      setCurrentLotSearchStreet: (street: string) =>
        set((state) => ({
          currentLotSearch: { ...state.currentLotSearch, street },
        })),
      setCurrentLotSearchLotNumber: (number: string) =>
        set((state) => ({
          currentLotSearch: { ...state.currentLotSearch, number },
        })),
      clearSuggestionInput: false,
      setClearSuggestionInput: (value: boolean) => set({ clearSuggestionInput: value }),
      clearLotSearchResults: () => {
        set({ searchLotResults: null });
      },
    }),
    {
      name: 'lot-search-storage',
      partialize: (state) => ({
        currentLotSearch: state.currentLotSearch,
        searchLotResults: state.searchLotResults,
      }),
      version: 1,
      migrate: (persistedState: any, version: number) => {
        return persistedState;
      },
    },
  ),
);
