import React, { useEffect, useState } from 'react';
import useOrderStore from '../store/orderStore';
import { useNavigate } from 'react-router-dom';
import { concatBookIdentifier } from '../helpers/helpers';
import PricingSection from '../components/pricing-section/PricingSection';
import useDocumentTitle from '../hooks/use-document-title';
import useAuthStore from '../store/authStore';
import { useUserStore } from '../store/userStore';
import useStore from '../store/store';
import { useTransactionStore } from '../store/transactionStore';
import useGuestOrderStore from '../store/guestOrderStore';

const Order: React.FC = () => {
  const { currentOrder, fulfilledOrder, handlePurchaseBook, error, isLoading } = useOrderStore();
  const { setBookId } = useGuestOrderStore();
  const [isPreviewLoading, setIsPreviewLoading] = React.useState(true);
  const [bookIdentifier, setBookIdentifier] = React.useState<string | null>(null);
  const { isLoggedIn } = useAuthStore();
  const { getIsBookPurchased } = useUserStore();
  const [isBookPurchased, setIsBookPurchased] = useState(false);
  const [isPurchasedLoading, setIsPurchasedLoading] = useState(true);
  const packages = useStore((state) => state.packages);
  const singlePackage = packages.find((p) => p.lookupsCount === 1);
  const { setTransactionId } = useTransactionStore();
  const [currentBookId, setCurrentBookId] = useState<number | null>(null);

  const navigate = useNavigate();

  useDocumentTitle('Podsumowanie i płatność');

  useEffect(() => {
    if (currentOrder?.previewData) {
      setBookIdentifier(concatBookIdentifier(currentOrder.previewData));
      setCurrentBookId(currentOrder.bookId);
      setIsPreviewLoading(false);
    }
  }, [currentOrder]);

  useEffect(() => {
    if (fulfilledOrder) {
      navigate('/zamowienie/dziekujemy');
    }
  }, [fulfilledOrder, navigate]);

  useEffect(() => {
    if (isLoggedIn && currentBookId) {
      const fetchIsBookPurchased = async () => {
        setIsPurchasedLoading(true);
        const purchased = await getIsBookPurchased(currentBookId as number);
        setIsBookPurchased(purchased);
        setIsPurchasedLoading(false);
      };

      fetchIsBookPurchased();
    }
  }, [isLoggedIn, currentBookId]);

  const handleOnClick = async () => {
    if (isLoggedIn) {
      await handlePurchaseBook(currentBookId as number);
    } else {
      setBookId(currentBookId as number);
      setTransactionId(singlePackage?.priceId as string);
      navigate('/checkout');
    }
  };

  if (isBookPurchased) {
    return (
      <>
        <section className="contact-us pb-0 section fill-content">
          <div className="container">
            <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-6  col-12">
                  <div className="form-main">
                    {isPurchasedLoading ? (
                      <div className="col-4 d-flex justify-content-center">
                        {' '}
                        <span className="search-loader"></span>
                      </div>
                    ) : (
                      <>
                        <div className="d-flex col-12 text-center justify-content-center">
                          <h4>Produkt już zakupiony</h4>
                        </div>
                        <div className="col-12 mt-20 d-flex">
                          <div className="col-12 text-center">
                            <button className="btn btn-primary" onClick={() => navigate('/moje-konto')}>
                              Przejdź do konta
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PricingSection />
      </>
    );
  }

  return (
    <>
      <section className="contact-us pb-0 section fill-content">
        <div className="container">
          <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-lg-6  col-12">
                <div className="form-main">
                  <div className="d-flex col-12 text-center justify-content-center">
                    <h4>Podsumowanie odkrycia</h4>
                  </div>
                  {error && (
                    <div className="d-flex justify-content-center mt-20">
                      <div className="alert alert-danger col-8 text-center">
                        <p>{error}</p>
                      </div>
                    </div>
                  )}
                  <div className="col-12 mt-20 d-flex">
                    <div className="col-12 text-center">
                      <b>Numer Księgi Wieczystej: {bookIdentifier}</b>
                    </div>
                  </div>
                  <div className="col-12 mt-20 d-flex">
                    <div className="col-12 text-center">Adres:</div>
                  </div>{' '}
                  <div className="col-12 mt-20 d-flex justify-content-center">
                    {!isLoading && (
                      <button className="btn btn-primary" onClick={handleOnClick}>
                        Odkryj numer Księgi Wieczystej
                      </button>
                    )}
                    {isPreviewLoading && (
                      <div className="col-4 d-flex justify-content-center">
                        <span className="search-loader"></span>
                      </div>
                    )}
                    {isLoading && (
                      <div className="col-4 d-flex justify-content-center">Trwa przetwarzanie płatności...</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLoggedIn && <PricingSection />}
    </>
  );
};

export default Order;
