import React, { useEffect, useState } from 'react';
import AchievementsItem from './AchievmentsItem';
import { Stats, fetchStats } from '../../services/statsApi';

const Achievements: React.FC = () => {
  const [stats, setStats] = useState<Stats | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedStats = await fetchStats();
      setStats(fetchedStats);
    };
    fetchData();
  }, []);

  if (!stats) {
    return null;
  }

  const formattedDate = new Date(stats.updatedAt).toLocaleDateString();
  return (
    <section className="our-achievement section">
      <div className="container">
        <div className="row">
          <AchievementsItem count={stats.books} title="ksiąg" delay="0s" duration={1} />
          <AchievementsItem count={stats.buildings} title="budynków" delay=".2s" duration={1} />
          <AchievementsItem count={stats.flats} title="lokali" delay=".3s" duration={2} />
          <AchievementsItem count={stats.lots} title="działek" delay=".4s" duration={3} />
        </div>
        <div className="row">
          <div className="col-12">
            <p className="text-end stats-date">
              Ostatnia aktualizacja danych: <strong>{formattedDate}</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Achievements;
