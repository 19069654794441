import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import AuthButtons from './AuthButtons';
import '../../scss/_header.scss';

const Header: React.FC = () => {
  return (
    <header className="w-full bg-white shadow-sm relative">
      <div className="container mx-auto px-4">
        <div className="flex items-center">
          <nav className="w-full flex items-center justify-between py-4">
            <Logo />
            <Navigation />
            <AuthButtons />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
