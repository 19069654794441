import React, { useEffect, useState } from 'react';
import SearchInputWithSuggestion from './SearchInputWithSuggestion';
import { City, fetchCitySuggestions } from '../../../../services/suggestionApi';
import SearchInput from './SearchInput';
import SearchButton from './SearchButton';

import styles from '../SearchForm.module.scss';
import { useNavigate } from 'react-router-dom';
import { emptyCity, useLotSearchStore } from '../../../../store/lotSearchStore';

import Tippy from '@tippyjs/react';

const LotSearchForm: React.FC = () => {
  const setScrollToResults = useLotSearchStore((state) => state.setScrollToResults);

  const selectedCity = useLotSearchStore((state) => state.currentLotSearch.city);
  const setCity = useLotSearchStore((state) => state.setCurrentLotSearchCity);
  const searchLot = useLotSearchStore((state) => state.searchLot);
  const selectedStreet = useLotSearchStore((state) => state.currentLotSearch.street);
  const setSelectedStreet = useLotSearchStore((state) => state.setCurrentLotSearchStreet);
  const lotIdentifier = useLotSearchStore((state) => state.currentLotSearch.identifier);
  const setLotIdentifier = useLotSearchStore((state) => state.setCurrentLotSearchIdentifier);
  const lotNumber = useLotSearchStore((state) => state.currentLotSearch.number);
  const setLotNumber = useLotSearchStore((state) => state.setCurrentLotSearchLotNumber);
  const [initialCityValue, setInitialCityValue] = useState<City | undefined>(undefined);
  const [errors, setErrors] = useState<{ city?: string; identifier?: string }>({});

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCity) {
      setInitialCityValue(selectedCity);
    }
  }, [selectedCity]);

  const validateForm = () => {
    const newErrors: { city?: string; identifier?: string } = {};

    if (!lotIdentifier && (!selectedCity || selectedCity.id === 0)) {
      if (!selectedCity || selectedCity.id === 0) {
        newErrors.city = 'Uzupełnij to pole';
      }
      if (!lotIdentifier) {
        newErrors.identifier = 'Uzupełnij to pole';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSearch = () => {
    if (validateForm()) {
      const params = {
        identifier: lotIdentifier,
        city: selectedCity,
        street: selectedStreet,
        number: lotNumber,
        page: 1,
      };
      setScrollToResults(true);
      navigate('/ksiegi-wieczyste-po-nr-dzialki');
      searchLot(params);
    }
  };

  const onCityInputChange = () => {
    setCity(emptyCity);
    setSelectedStreet('');
    setLotNumber('');
    setErrors((prev) => ({ ...prev, city: undefined }));
  };

  const handleIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLotIdentifier(e.target.value);
    setErrors((prev) => ({ ...prev, identifier: undefined }));
  };

  return (
    <div className={`row ${styles.searchInputs}`}>
      <div className="col-12">
        <p className={`${styles.inputHeading}`}>Według identyfikatora:</p>
      </div>
      <div className="col-12">
        <SearchInput
          placeholder="Identyfikator"
          value={lotIdentifier}
          onChange={handleIdentifierChange}
          error={errors.identifier}
        />
      </div>
      <div className="col-12">
        <p className={`${styles.inputHeading}`}>Według adresu:</p>
      </div>
      <div className="col-12">
        <SearchInputWithSuggestion<City>
          placeholder="Miasto"
          onSelect={setCity}
          fetchSuggestions={fetchCitySuggestions}
          onInputChange={onCityInputChange}
          initialValue={initialCityValue}
          error={errors.city}
        />
      </div>
      <div className="col-12">
        <SearchInput
          placeholder="Ulica (opcjonalnie)"
          disabled={!selectedCity || selectedCity.id === 0}
          value={selectedStreet}
          onChange={(e) => setSelectedStreet(e.target.value)}
        />
      </div>
      <div className="col-12">
        <SearchInput
          placeholder="Numer działki"
          disabled={!selectedCity || selectedCity.id === 0}
          value={lotNumber}
          onChange={(e) => setLotNumber(e.target.value)}
        />
      </div>
      <div className="col-12">
        <SearchButton onClick={handleSearch} />
      </div>
    </div>
  );
};

export default LotSearchForm;
