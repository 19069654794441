import React, { useEffect, useRef } from 'react';
import HeroArea from '../components/hero-area/HeroArea';
import Achievements from '../components/achievments/Achievments';
import PricingSection from '../components/pricing-section/PricingSection';
import { useLocation } from 'react-router-dom';
import useDocumentTitle from '../hooks/use-document-title';

const Home: React.FC = () => {
  const pricingSectionRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useDocumentTitle('Strona główna');

  useEffect(() => {
    const scrollToPricingSection = () => {
      if (location.hash === '#pakiety' && pricingSectionRef.current) {
        const offset = 100; // Adjust the offset value according to your header height
        const offsetTop = pricingSectionRef.current.offsetTop - offset;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        });
      }
    };

    const scrollToTop = () => {
      if (location.hash === '#search') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };

    const timer = setTimeout(() => {
      scrollToPricingSection();
      scrollToTop();
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, [location.hash]);

  return (
    <div>
      <HeroArea isHomeScreen={true} />
      <Achievements />
      <PricingSection ref={pricingSectionRef} />
    </div>
  );
};

export default Home;
