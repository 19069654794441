import React from 'react';
import NavItem from './NavItem';

const DashboardSidebar: React.FC = () => {
  return (
    <div className="dashboard-sidebar">
      <div className="dashboard-menu p-2 pb-1">
        <ul className="row d-flex justify-content-center">
          <li className="col-12 col-md-4 text-center m-0">
            <NavItem to="/moje-konto/ksiegi" iconClass="book">
              Odkryte księgi
            </NavItem>
          </li>
          <li className="col-12 col-md-4 text-center m-0">
            <NavItem to="/moje-konto/zakupy" iconClass="shopping-basket">
              Moje zakupy
            </NavItem>
          </li>
          <li className="col-12 col-md-4 text-center m-0">
            <NavItem to="/moje-konto/edytuj" iconClass="pencil-alt">
              Edytuj konto
            </NavItem>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardSidebar;
