import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { BookPreviewData, fetchPreview } from '../services/previewApi';
import { purchaseBook } from '../services/purchaseApi';
import { useUserStore } from './userStore';

export interface OrderData {
  bookId: number;
  previewData: BookPreviewData;
}

interface OrderState {
  currentOrder: OrderData | null;
  fulfilledOrder: OrderData | null;
  isLoading: boolean;
  error: string | null;
  setCurrentOrder: (bookId: number, previewData?: BookPreviewData) => void;
  clearCurrentOrder: () => void;
  handlePurchaseBook: (bookId: number) => Promise<void>;
  clearFullfilledOrder: () => void;
}

const useOrderStore = create<OrderState>()(
  persist(
    (set) => ({
      currentOrder: null,
      fulfilledOrder: null,
      isLoading: false,
      error: null,
      setCurrentOrder: async (bookId, previewData) => {
        let bookPreviewData = previewData;
        set({ error: null });
        set({ fulfilledOrder: null });
        if (!previewData) {
          try {
            bookPreviewData = await fetchPreview(bookId);
            set({ currentOrder: { bookId, previewData: bookPreviewData } });
          } catch (err) {
            console.error('Error fetching preview data:', err);
            set({ error: 'Wystąpił błąd podczas pobierania podglądu księgi' });
            return;
          }
        } else {
          set({ currentOrder: { bookId, previewData } });
        }
      },
      clearCurrentOrder: () => {
        set({ currentOrder: null });
      },
      clearFullfilledOrder: () => {
        set({ fulfilledOrder: null });
      },
      handlePurchaseBook: async (bookId) => {
        try {
          set({ isLoading: true, error: null });
          const bookData = await purchaseBook(bookId);
          if (bookData.status === 'success') {
            if (bookData.data) {
              set({ fulfilledOrder: { bookId, previewData: bookData.data } });
            }
          } else {
            set({ error: bookData.error });
          }
        } catch (err) {
          set({ error: 'Wystąpił błąd podczas zakupu księgi. Spróbuj ponownie lub skontaktuj się z nami.' });
        } finally {
          useUserStore.getState().fetchUserLookupsAction();
          set({ isLoading: false });
        }
      },
    }),
    {
      name: 'order-storage',
      storage: createJSONStorage(() => sessionStorage),
      version: 1,
      migrate: (persistedState: any) => {
        return persistedState;
      },
    },
  ),
);

export default useOrderStore;
