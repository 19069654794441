import React from 'react';
import { Link, NavLink } from 'react-router-dom';

interface NavigationItemProps {
  to: string;
  children: React.ReactNode;
  icon?: string;
  onClick?: () => void;
  isNavLink?: boolean;
  className?: string;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  to,
  children,
  icon,
  onClick,
  isNavLink = false,
  className = '',
}) => {
  const baseClasses =
    'nav-link text-lg font-medium relative inline-flex items-center py-2 lg:py-2.5 lg:px-1 hover:text-[#4b3264]';
  const combinedClasses = `${baseClasses} ${className}`;

  const content = (
    <>
      {icon && <i className={`lni ${icon} mr-2`}></i>}
      {children}
    </>
  );

  if (isNavLink) {
    return (
      <NavLink to={to} onClick={onClick} className={combinedClasses}>
        {content}
      </NavLink>
    );
  }

  return (
    <Link to={to} onClick={onClick} className={combinedClasses}>
      {content}
    </Link>
  );
};

export default NavigationItem;
