import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuthStore from '../../store/authStore';
import { useUserStore } from '../../store/userStore';

const AuthButtons: React.FC = () => {
  const { isLoggedIn } = useAuthStore();

  const { logout } = useAuthStore();

  useEffect(() => {
    if (isLoggedIn) {
      useUserStore.getState().fetchUserLookupsAction();
    }
  }, [isLoggedIn]);

  const userLookups = useUserStore((state) => state.userLookups);

  return (
    <div className="login-button hidden text-[16px] lg:flex flex-row ">
      <ul className="flex flex-row">
        {!isLoggedIn ? (
          <>
            <li className="pr-2 mr-2 border-r border-[#4b3264]">
              <Link to="/logowanie">
                <i className="lni lni-enter text-[#4b3264] mr-1"></i> Logowanie
              </Link>
            </li>
            <li>
              <Link to="/rejestracja">
                <i className="lni lni-user text-[#4b3264] mr-1"></i> Rejestracja
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="pr-2 mr-2 border-r border-[#4b3264] flex flex-row gap-x-2">
              <li>
                <i className="lni lni-magnifier text-[#4b3264]"></i> {userLookups}
              </li>
              <Link to="/moje-konto/ksiegi">
                <i className="lni lni-user text-[#4b3264] mr-1"></i>
                Moje konto
              </Link>
            </li>
            <li>
              <Link to="/" onClick={logout}>
                <i className="lni lni-exit text-[#4b3264] mr-1"></i>
                Wyloguj
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default AuthButtons;
