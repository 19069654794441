import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './SearchForm.module.scss';

import { SearchType } from './Enums';
import AddressSearchForm from './components/AddressSearchForm';
import LotSearchForm from './components/LotSearchForm';

interface IProps {
  isHomeScreen?: boolean;
  searchType?: SearchType;
}

const SearchForm: React.FC<IProps> = ({ isHomeScreen, searchType }) => {
  const [activeTab, setActiveTab] = useState(searchType || 'address');
  const navigate = useNavigate();

  const handleTabClick = (tab: string) => {
    isHomeScreen ? setActiveTab(tab) : navigateToSearch(tab as SearchType);
  };

  const headingText = 'Wyszukaj księgi po:';

  const renderSearchForm = () => {
    if (activeTab === 'address') {
      return <AddressSearchForm />;
    } else {
      return <LotSearchForm />;
    }
  };

  const navigateToSearch = (searchType: SearchType) => {
    if (searchType === 'address') {
      navigate('/ksiegi-wieczyste-po-adresie');
    } else {
      navigate('/ksiegi-wieczyste-po-nr-dzialki');
    }
  };

  return (
    <div>
      <div className={styles.searchHeading}>
        <h3 className="heading-title">{headingText}</h3>
      </div>
      <div className={`flex flex-row gap-x-4`}>
        <div
          className={`flex-1 flex bg-white py-3 rounded-t-lg items-end justify-center cursor-pointer ${
            activeTab !== 'address' ? 'shadow-[inset_0_-6px_8px_rgba(0,0,0,0.2)]' : ''
          }`}
          onClick={() => handleTabClick('address')}
        >
          <h5>Adresie</h5>
        </div>
        <div
          className={`flex-1 flex bg-white py-3 rounded-t-lg items-end justify-center cursor-pointer ${
            activeTab !== 'lot' ? 'shadow-[inset_0_-6px_8px_rgba(0,0,0,0.2)]' : ''
          }`}
          onClick={() => handleTabClick('lot')}
        >
          <h5>Numerze działki</h5>
        </div>
      </div>
      <div className={`${styles.searchForm} rounded-b-lg search-form style2 wow fadeInRight`} data-wow-delay=".5s">
        {renderSearchForm()}
      </div>
    </div>
  );
};

export default SearchForm;
