import { AxiosError } from 'axios';
import axios from './axios';

interface LoginResponse {
  token: string;
  role?: string;
}

export const loginUser = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await axios.post('/auth/login', { email, password });
    return {
      token: response.data.token,
      role: response.data.role,
    };
  } catch (error) {
    throw new Error('Logowanie nie powiodło się. Spróbuj ponownie.');
  }
};

export const registerUser = async (userData: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
}): Promise<string> => {
  try {
    const response = await axios.post('/auth/register', userData);
    return response.data.token;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw error;
    }
    throw new Error('Registration failed');
  }
};

export const validateToken = async (token: string): Promise<boolean> => {
  try {
    const response = await axios.get('/auth/validateToken', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.valid;
  } catch (error) {
    console.error('Token validation failed', error);
    return false;
  }
};

// export const changePassword = async (currentPassword: string, newPassword: string, token: string): Promise<void> => {
//   try {
//     await axios.post(
//       '/auth/change-password',
//       { currentPassword, newPassword },
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       },
//     );
//   } catch (error) {
//     if (error instanceof AxiosError) {
//       throw error.response?.data.msg;
//     }
//     throw new Error('Password change failed');
//   }
// };
